<template>
  <div>
    <template>
      <div>
        <v-breadcrumbs
          :items="breadcrumb"
          class="px-0 pt-0"
        >
        </v-breadcrumbs>
      </div>
    </template>
    <router-link
      to="/bank-teacher"
      class="text-decoration-none text-subtitle-2 font-weight-bold"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </router-link>
    <h3 class="my-8">
      Tambah Bank Soal
    </h3>
    <v-card class="pa-4">
      <v-card-text>
        <div class="mb-8">
          <h3>
            Informasi Bank Soal
          </h3>
          <span>
            Informasi untuk bank soal
          </span>
        </div>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="questionBank.name"
                :rules="[validation.required]"
                outlined
                dense
                label="Judul Bank Soal"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="12"
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="questionBank.bank_category_uuid"
                :items="categories"
                outlined
                :rules="[validation.required]"
                dense
                item-value="uuid"
                item-text="name"
                label="Kategori"
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="12"
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="questionBank.level"
                :rules="[validation.required]"
                outlined
                :items="levels"
                item-text="name"
                dense
                item-value="uuid"
                label="Tingkatan"
              ></v-autocomplete>
            </div>
          </v-col>
        </v-row>
        <div class="mb-8">
          <div class="mb-6 mt-6">
            <h3>Icon</h3>
            <span>Icon untuk Bank Soal</span>
          </div>
          <div class="d-flex mb-10">
            <v-card
              width="120"
              height="110"
              class="me-4 d-flex align-center justify-center"
              style="border: 1px dashed #959595;"
              color="#FCFCFC"
              @click="addIcon"
            >
              <div class="text-center">
                <v-icon
                  v-if="!questionBank.icon"
                  large
                >
                  {{ icons.mdiPlus }}
                </v-icon>
                <v-img
                  v-else
                  v-model="questionBank.bank_icon_uuid"
                  :rules="[validation.required]"
                  width="40"
                  :src="questionBank.icon"
                  class="mx-auto"
                ></v-img>
              </div>
            </v-card>
            <div
              class="d-flex align-content-space-between flex-wrap"
              style="height: 110px;width: 340px;"
            >
              <span class="font-weight-normal">
                Icon akan ditampilkan di halaman materi pelajaran
                <a
                  class="font-weight-bold"
                  @click="addIcon"
                >Pilih Icon</a>
              </span>
              <small>*Icon akan dipilih secara default jika anda ingin melewatkannya</small>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="mb-8">
            <h3>
              Status Soal
            </h3>
            <span>
              Kamu dapat menempatkan soal ke dalam beberapa pilihan di bawah
            </span>
          </div>
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <div>
                <v-autocomplete
                  v-model="questionBank.type"
                  :rules="[validation.required]"
                  outlined
                  dense
                  :items="types"
                  label="Status Soal"
                  class="mb-0"
                ></v-autocomplete>
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- <div>
          <v-textarea
            v-model="questionBank.discussion"
            label="Pembahasan"
            outlined
            dense
          >
          </v-textarea>
        </div> -->
        <v-alert
          text
          color="info"
        >
          <p><strong>Keterangan</strong></p>
          <strong>Private</strong> : Soal hanya dapat dilihat Guru, <strong>Public</strong> : Soal dapat dilihat seluruh sekolah yang ada di Literasia, <strong>Sekolah</strong> : Soal hanya dapat dilihat oleh Sekolah Guru, <strong>Kabupaten</strong> : Soal hanya dapat dilihat Sekolah seluruh Kabupaten, <strong>Provinsi</strong> : Soal hanya dapat dilihat Sekolah seluruh Provinsi.
        </v-alert>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            large
            :loading="isLoadingButton"
            :disabled="!formValidLesson"
            @click="addBankQuestion()"
          >
            Simpan
          </v-btn>
          <v-btn
            color="primary"
            outlined
            large
            @click="returnBack"
          >
            Batal
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <ModalDialog
      :visible="modalDialogIcon"
      :width="500"
      :hidden-actions="false"
      header="Pilih Icon"
      @close-modal="handlerButton"
      @close="modalDialogIcon = false"
    >
      <template v-slot:body>
        <p>Pilih salah satu icon:</p>
        <v-row>
          <v-col
            v-for="bankIcon in bankIcons"
            :key="bankIcon.icon"
            cols="3"
          >
            <v-card
              height="80"
              outlined
              class="pa-4 elevation-0 d-flex justify-center align-center"
              clickable
              :color="cardActive == bankIcon.uuid ? 'primary border-none' : ''"
              @click="getIcon(bankIcon.icon, bankIcon.uuid)"
            >
              <div>
                <v-img
                  width="40"
                  :src="bankIcon.icon"
                  class="mx-auto"
                ></v-img>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          dense
          large
          @click="chooseIcon(icon.icon, icon.uuid)"
        >
          Pilih
        </v-btn>
        <v-btn
          color="primary"
          dense
          large
          outlined
          @click="handlerButton"
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import ModalDialog from '@/views/components/ModalDialog.vue'
import { integerValidator, required } from '@core/utils/validation'
import { mdiArrowLeft, mdiPlus } from '@mdi/js'

export default {
  name: 'BankQuestionAdd',
  components: {
    ModalDialog,
  },
  data() {
    return {
      icons: { mdiPlus, mdiArrowLeft },
      validation: {
        required,
        integerValidator,
      },
      categories: [],
      required: '',
      rules: {
        required: value => !!value || 'harus diisi',
      },
      types: ['Umum', 'Sekolah', 'Kabupaten', 'Provinsi'],
      sorts: ['Semua', 'Terbaru', 'Banyak Soal', 'Sedikit Soal', 'A-Z', 'Z-A'],
      levels: [],
      levels_selected: [],
      school: [],
      school_uuid: '',
      questionsBank: [],
      questionBank: {
        employee_uuid: '',
        school_uuid: '',
        bank_category_uuid: null,
        name: '',
        type: '',
        level: '',
        icon: null,
      },
      icon: {
        uuid: '',
        icon: '',
      },
      level: [],
      breadcrumb: [
        {
          text: 'Bank Soal',
          disabled: false,
          href: '/bank-teacher',
        },
        {
          text: 'Tambah Bank Soal',
          disabled: true,
        },
      ],
      role: '',
      bankIcons: [],
      modalDialogIcon: false,
      cardActive: '',
      isLoadingButton: false,
      isDisabledButton: false,
      formValidLesson: false,
    }
  },
  watch: {
    questionBank: {
      handler() {
        const valid = []
        const requiredField = ['name', 'type', 'level', 'icon', 'bank_category_uuid']
        Object.entries(this.questionBank).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValidLesson = !valid.includes(false)
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getSchoolUuid()
    await this.getRole()
    await this.getCategories()
    this.getLevel()
    this.getBankIcon()
    this.resetForm()
  },
  methods: {
    validate() {
      this.validate()
    },
    returnBack() {
      this.$router.back()
    },
    async getCategories(params = {}) {
      this.$services.ApiServices.list('bankcategory', { ...params, per_page: 'all' }).then(
        ({ data }) => {
          this.categories = data.data
        },
        err => console.error(err),
      )
    },

    resetForm() {
      this.questionBank.bank_category_uuid = ''
      this.questionBank.name = ''
      this.questionBank.type = ''
      this.questionBank.level = ''
      this.questionBank.answer = ''
      this.questionBank.bank_category_uuid = null
      this.questionBank.icon = null
    },
    chooseIcon(icon, uuid) {
      this.questionBank.icon = icon
      this.questionBank.bank_icon_uuid = uuid
      this.modalDialogIcon = false
    },
    addIcon() {
      this.modalDialogIcon = true
    },
    getIcon(icon, uuid) {
      this.icon.icon = icon
      this.icon.uuid = uuid
      this.cardActive = uuid
    },
    getBankIcon() {
      this.$services.ApiServices.list('bankicon').then(
        ({ data }) => {
          this.bankIcons = data.data
        },
        err => console.error(err),
      )
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.questionBank.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: "You don't have access to this page",
            color: 'error',
          })

          return
        }
        this.questionBank.school_uuid = el.school.uuid
        this.questionBank.employee_uuid = el.uuid
      })

      return true
    },
    getRole() {
      this.role = JSON.parse(localStorage.getItem('user')).user.role
      if (this.role === 'admin') {
        this.school_uuid = null
        this.employee_uuid = null
      }
    },
    getLevel() {
      this.level = JSON.parse(localStorage.getItem('user')).user.details
      this.role = JSON.parse(localStorage.getItem('user')).user.role
      if (this.role !== 'admin') {
        this.level.forEach(el => {
          if (el.school.jenjang === 'SD') {
            this.levels = ['Kelas 1 SD', 'Kelas 2 SD', 'Kelas 3 SD', 'Kelas 4 SD', 'Kelas 5 SD', 'Kelas 6 SD']
          } else if (el.school.jenjang === 'SMP') {
            this.levels = ['Kelas 1 SMP', 'Kelas 2 SMP', 'Kelas 3 SMP']
          } else if (el.school.jenjang === 'SMA') {
            this.levels = ['Kelas 1 SMA', 'Kelas 2 SMA', 'Kelas 3 SMA']
          } else if (el.school.jenjang === 'SMK') {
            this.levels = ['Kelas 1 SMK', 'Kelas 2 SMK', 'Kelas 3 SMK']
          }
        })
      } else {
        this.levels = [
          'Semua',
          'Kelas 1 SD',
          'Kelas 2 SD',
          'Kelas 3 SD',
          'Kelas 4 SD',
          'Kelas 5 SD',
          'Kelas 6 SD',
          'Kelas 1 SMP',
          'Kelas 2 SMP',
          'Kelas 3 SMP',
          'Kelas 1 SMA',
          'Kelas 2 SMA',
          'Kelas 3 SMA',
        ]
      }
    },
    async addBankQuestion() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      await this.$services.ApiServices.add('questionbank', this.questionBank).then(
        ({ data }) => {
          this.questionBank = data.data
          this.showSnackbar({
            text: 'Add question bank has successfully',
            color: 'success',
          })
          this.isLoadingButton = false
          this.resetForm()
          console.clear()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
      this.isDisabledButton = false
      this.questionBank.bank_icon_uuid = null
      this.resetForm()
      this.returnBack()
    },
    handlerButton() {
      this.questionBank.icon = null
      this.questionBank.bank_icon_uuid = null
      this.modalDialogIcon = false
      this.cardActive = ''
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.clickable {
  cursor: pointer;
}
</style>
